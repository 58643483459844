@import "../../App.scss";

.logincontainer{
   
    .formdiv{
        text-align: center;
        margin:10% 30%;
        padding: 1% 5% 5% 5%;
        border-radius: 5%;
        h1{
            font-size: 3.5em;
            font-weight: 900;
            color:#5a1e96;
        }
        form{
            margin-top: 5%;
            .inputdiv{
                margin-top: 5%;
                label{
                    display: block;
                    text-align: left;
                    font-size: 1.25em;
                    font-family:$secondaryFont;
                    font-weight: bolder;
                    margin-left: 2%;
                    margin-bottom: 1.5%;
                }
                input{
                    width: 100%;
                    padding:2% 2%;
                    outline: none;
                    border: 1px solid $grey;
                    border-radius: 10px;
                    font-family: $secondaryFont;
                }
                .errorinput{
                border: 1px solid red;
                }
                input:focus{
                    border: 1px solid $purple;
                }
                .errorname{
                    text-align: left;
                    color: red;
                    margin-left: 2%;
                    margin-top: 1%;
                }
            }
            .submit-button{
                margin-top: 5%;
            }
        }
    }
}

@media (max-width: 992px) {

}
@media (max-width: 768px) {


}
@media (max-width: 576px) {
    .logincontainer{
   
        .formdiv{
            text-align: center;
            margin:10% 5%;
            padding: 1% 5% 5% 5%;
            border-radius: 5%;
            h1{
                font-size: 3.5em;
                font-weight: 900;
                color:#5a1e96;
            }
            form{
                margin-top: 5%;
                .inputdiv{
                    margin-top: 7%;
                    label{
                        display: block;
                        text-align: left;
                        font-size: 1.25em;
                        font-family:$secondaryFont;
                        font-weight: bolder;
                        margin-left: 2%;
                        margin-bottom: 1.5%;
                    }
                    input{
                        width: 100%;
                        padding:3%;
                        outline: none;
                        border: 1px solid $grey;
                        border-radius: 10px;
                        font-family: $secondaryFont;
                    }
                    .errorinput{
                    border: 1px solid red;
                    }
                    input:focus{
                        border: 1px solid $purple;
                    }
                    .errorname{
                        text-align: left;
                        color: red;
                        margin-left: 2%;
                        margin-top: 1%;
                    }
                }
                .submit-button{
                    margin-top: 5%;
                    padding:3% 10%;
                }
            }
        }
    }
}