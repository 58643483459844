@import "../../App.scss";


.report-detail-heading{
    color:$purple;
}

.reports{
    .heading{
        color:$purple;
        margin:3% 3%;
    }
}

.col-10{
   overflow-y: scroll;

}
.tablediv{
    margin:3% ;
    .table-row:hover{
    background-color: $lightPink;
    cursor: pointer;
    }
    
}