@import "../../App.scss";

.vendordiv{
    h1{
        text-align: center;
        padding-top: 1%;
    }
    form{
        padding:2% 10%;
    }
    .btn{
        margin-top: 2%;
        padding:1% 5%;
    }
}